import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";
import PGSideBar from '../../components/pg-side-bar';

import '../../styles/cus.css';

const PatentGuide_DesignPatent = () => {
    const seo = {
        metaDesc: 'How Is Design Patent Different From Utility Patent? - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="How Is Design Patent Different From Utility Patent? - Inventiv.org" canonical='/patent-guide' seo={seo} />
            <div class="sub-banner main-banner">
                <div class="container">
                    <div class="breadcrumb-area" style={{ top: 30 + '%' }}>
                        <h1 className='fw-special-title'>&nbsp;</h1>
                        <h1 className='title-banner'>How Is Design Patent Different From Utility Patent?</h1>
                    </div>
                </div>
            </div>

            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <div className='row'>
                        <div className='col-md-8 col-sm-12'>
                            <figure className='text-center'>
                                <img width="300" height="300"
                                    src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    data-src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    class="vc_single_image-img attachment-medium lazyloaded" alt="" decoding="async"
                                    data-srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w"
                                    data-sizes="(max-width: 300px) 100vw, 300px" data-pagespeed-url-hash="2358126750" sizes="(max-width: 300px) 100vw, 300px"
                                    srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w" />
                            </figure>
                            <div class="wpb_wrapper">
                                <h2>How is Design Patent Different From Utility Patent?</h2>
                                <p><span style={{ fontWeight: 400 }}>Design patents protect how something looks, while utility patents protect how
                                    something operates and the way it is constructed.&nbsp; Consequently, to obtain a design patent,
                                    the applicant must meet different requirements; the invention must still be novel and
                                    non-obvious, but it must also be original and an ornamental design of a useful
                                    item.&nbsp;&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>Here’s some information to guide your design patent application:</span></p>
                                <ul>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Originality is not merely imitating
                                        something already constructed or naturally occurring.&nbsp; However, courts have defined
                                        “original” loosely.&nbsp;&nbsp;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Design cannot be dictated by the item’s
                                        function.&nbsp;&nbsp;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>The design must be visible during intended
                                        and usual use of the item, or at important commercial times, such as when you offer to
                                        sell the item.&nbsp;&nbsp;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>The item must be
                                        man-made.&nbsp;&nbsp;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>You cannot design patent paintings,
                                        photographs, decals or silk-screened items.</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Factors in determining obvious are, for
                                        the most part, similar to those under a utility patent.&nbsp; Examples of non-obvious
                                        may include:</span>
                                        <ul>
                                            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>using an unexpected
                                                medium;</span></li>
                                            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>omitting a particular
                                                design element thought to be crucial; or&nbsp;</span></li>
                                            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>creating a new
                                                juxtaposition of design elements.</span></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div className='mt-5'>
                                <p>
                                <a href='/inventor-resources' className='btn btn-warning rounded-pill px-5'>Download Provisional Patent Assistant Now!</a>
                                </p>
                            </div>
                        </div>

                        <div className='col-md-4 col-sm-12'>
                            <PGSideBar></PGSideBar>
                        </div>
                    </div>

                </div>
            </section>

        </Layout>
    )
};

export default PatentGuide_DesignPatent;